import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import './Button.scss';

export type As<Props = any> = React.ElementType<Props>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithAs<Props = {}, Type extends As = As> = Props &
	Omit<React.ComponentProps<Type>, 'renderAs' | keyof Props> & {
		/**
		 * Overide the element of which the component renders as.
		 */
		renderAs?: Type;
	};

export type ComponentWithAs<Props, DefaultType extends As> = {
	<Type extends As>(
		props: PropsWithAs<Props, Type> & { renderAs: Type }
	): JSX.Element;
	(props: PropsWithAs<Props, DefaultType>): JSX.Element;
};

const DEFAULT_ELEMENT = 'button';

export type ButtonVariant =
	| 'primary' // MPD Grey
	| 'secondary' // AO Green
	| 'tertiary'
	| 'link'
	| 'white' // Blue border (sort by)
	| 'pill'
	| 'dark'
	| 'outlined';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	renderAs?: As;
	variant?: ButtonVariant;
	disabled?: boolean;
	color?: never;
	className?: string;
	children: ReactNode;
}

export const BUTTON_VARIANTS: Record<ButtonVariant, string> = {
	primary: 'primary',
	secondary: 'secondary',
	tertiary: 'tertiary',
	link: 'link',
	white: 'white',
	pill: 'pill',
	dark: 'dark',
	outlined: 'outlined',
};

export const Button = forwardRef(function CustomButton(
	{
		renderAs: ElementType = DEFAULT_ELEMENT,
		variant,
		disabled = false,
		children,
		className,
		...props
	}: ButtonProps,
	ref
) {
	const isButtonElement = ElementType === 'button';
	const isLink = 'href' in props || 'to' in props;

	return (
		<ElementType
			className={classNames(
				'Button',
				{
					Button__Disabled: disabled,
					[`Button__${variant}`]: true,
				},
				className
			)}
			type={isButtonElement ? 'button' : undefined}
			disabled={isButtonElement && disabled}
			aria-disabled={disabled}
			role={!isLink && !isButtonElement ? 'button' : undefined}
			ref={ref}
			{...props}
		>
			{children}
		</ElementType>
	);
}) as ComponentWithAs<ButtonProps, typeof DEFAULT_ELEMENT>;
