import React from 'react';
import { Button } from 'components/Common/Button';
import { Handset } from 'components/types';
import {
	CombinedHandsetImage,
	LayeredHandsetImage,
} from 'components/Common/Handset';
import { urlToRelative } from 'components/Common/url-to-relative';
import classnames from 'classnames';
import './PhoneSlide.scss';

function PhoneSlide({ handset }: { handset: Handset }) {
	const handsetUrl = urlToRelative(
		globalThis
			.Router('handset.show', {
				manufacturer_slug: handset.manufacturer.slug,
				model_slug: handset.modelSlug,
			})
			.toString()
	);

	function trackPhoneSlideClicked() {
		globalThis.dataLayer.push({
			event: 'Latest Phones Slide Click',
			eventCategory: 'HomePage',
			eventAction: 'Latest Phones',
			eventLabel: handset.slug,
		});
	}

	if (Object.keys(handset.deals).length === 0) {
		return <></>;
	}

	// TODO: remove checking for old format
	const deals =
		handset.deals['new-contract'] ||
		handset.deals['free-from']['new-contract'];
	if (
		deals[handset.defaultColour.slug][handset.defaultCapacity.slug]
			.length === 0
	) {
		return null;
	}
	const deal =
		deals[handset.defaultColour.slug][handset.defaultCapacity.slug][0].deal;

	return (
		<div
			className={classnames('phone-slide', {
				'phone-slide-wide': deal.variant.images.featured === 'combined',
			})}
		>
			<a
				href={handsetUrl}
				className="phone-slide-link"
				aria-hidden={true}
				onClick={() => trackPhoneSlideClicked()}
			>
				&nbsp;
			</a>
			<div className="phone-slide-inner u-pb--small u-px--small u-px--xsmall--bp-medium  u-ta--center">
				<div className="u-font-group--title u-fz--base-small u-fw--500 u-py--none">
					{handset.manufacturer.name}
				</div>
				<div className="u-mb--tiny handset-model">
					<strong>{handset.model}</strong>
				</div>

				{/* <div className="u-ta--center"> */}
				<div
					style={{ height: '200px' }}
					className="phone-slide-handset-image"
				>
					{deal.variant.images.featured === 'combined' ? (
						<CombinedHandsetImage
							className="u-mx--auto"
							images={deal.variant.images}
							displayName={deal.variant.displayName}
							height="200"
							displayHeight={200}
							retina={true}
							cdnOptions={{ height: 200, fit: 'contain' }}
						/>
					) : (
						<LayeredHandsetImage
							containerProps={{
								style: {
									height: '100%',
									width: '100%',
								},
							}}
							displayName={deal.variant.displayName}
							images={deal.variant.images}
							height="200"
							displayHeight={200}
							retina={true}
							cdnOptions={{ height: 200, fit: 'contain' }}
						/>
					)}
				</div>

				<div className="phone-slide-deal-details u-py--tiny">
					<div className="u-fz--base-small u-fw--500 u-py--micro handset-upfront">
						{deal.upfrontDisplay} Upfront From
					</div>
					<div
						className="u-fz--display-large u-c--primary-text u-fw--700"
						style={{ lineHeight: 1.15 }}
					>
						{deal.monthlyAfterCashbackDisplay}
					</div>
					<div className="u-fw--400 u-font-group--caption u-pb--tiny u-pt--micro handset-per-month">
						per month / {deal.tariff.length} months
					</div>
					<Button
						renderAs="a"
						variant="primary"
						aria-label={`view ${handset.manufacturer.name} ${handset.model} deals`}
						href={handsetUrl}
						onClick={() => trackPhoneSlideClicked()}
					>
						View Deals
					</Button>
				</div>
				{/* </div> */}
			</div>
		</div>
	);
}
export { PhoneSlide };
