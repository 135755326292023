import { Button } from '@ao-internal/react-components';
import { Delivery as D } from 'components/Handset/DealStockMessage/delivery';
import { Deal, Variant } from 'components/types';
import { add, format, isSunday } from 'date-fns';
import React, { ReactNode, useMemo, useState } from 'react';
import { BankHoliday } from 'services/BankHoliday/BankHoliday';
import './StockMessage.scss';
import { StockMessageModal } from './StockMessageModal';
import { StockContext, StockContextType } from './useStockContext';
import * as classname from 'classnames';
import { getDeliveryDateDuringPeriod, isDateBetweenBreak } from 'helpers/utils';

export default function StockMessage({
	deal,
	variant,
	children,
	className,
	modal = false,
}: StockMessageProps): JSX.Element {
	const [modalOpen, setModalOpen] = useState(false);

	const buildDeal = (deal ? deal : { variant }) as Deal;
	const delivery = useMemo(() => new D(buildDeal), [deal, variant]);
	const bankHoliday = useMemo(() => new BankHoliday(), []);

	if (!buildDeal.variant) return <></>;
	if (delivery.die) return <></>;

	const isNextDay = delivery.getIsNextDayDelivery();
	const inStock =
		!NO_STOCK_NAMES.includes(buildDeal.variant.stock) || isNextDay;
	const estimatedDelivery = delivery.getEstimatedDeliveryDateFormatted();

	const contextProps: StockContextType = {
		modalOpen,
		setModalOpen,
		isNextDay,
		inStock,
		estimatedDelivery,
		deliveryMessage: generateDeliveryMessage(),
		stockMessage: generateStockMessage(),
		class: STOCK_MESSAGES_CLASS[buildDeal.variant.stock],
		timer: delivery.calcTimeLeftForNextDay(),
		cutOff: delivery.cutoff,
	};

	function generateStockMessage() {
		if (buildDeal.variant.stock === 'none' && isNextDay) {
			return STOCK_MESSAGES['low'];
		}

		return STOCK_MESSAGES[buildDeal.variant.stock];
	}

	function generateDeliveryMessage() {
		let msg: React.ReactNode = 'Free Delivery';

		if (isNextDay) {
			msg = 'Next Day Delivery';
		}

		if (!inStock) {
			msg = (
				<>
					{estimatedDelivery}
					<span className="StockMessage--DeliveryAppend">
						{' '}
						delivery
					</span>
				</>
			);
		}

		if (bankHoliday.isBankHoliday) {
			if (inStock) {
				let resumesOn = add(new Date(bankHoliday.bankHoliday[0].date), {
					days: 1,
				});
				if (isSunday(resumesOn)) {
					resumesOn = add(new Date(bankHoliday.bankHoliday[0].date), {
						days: 2,
					});
				}
				msg = 'Delivered from the ' + format(resumesOn, 'do');
			}
		}

		if (isDateBetweenBreak()) {
			const deliveredFrom = getDeliveryDateDuringPeriod().formattedDate;

			msg = `Delivered from the ${deliveredFrom}`;
		}

		return msg;
	}

	const concatenatedClassName = classname(
		'StockMessage',
		`StockMessage__${contextProps.class}`,
		className,
		{
			[`StockMessage__Modal`]: modal,
		}
	);

	const handleClick = () => {
		if (isDateBetweenBreak()) return;

		setModalOpen(true);
	};

	if (modal) {
		return (
			<StockContext.Provider value={contextProps}>
				<Button onClick={handleClick} className={concatenatedClassName}>
					<div
						className={`StockMessage--Container u-w--100 ${
							isDateBetweenBreak()
								? 'StockMessage--Container--Break'
								: ''
						}`}
					>
						{children}
					</div>
				</Button>
				<StockMessageModal activeVariant={variant} />
			</StockContext.Provider>
		);
	}

	return (
		<StockContext.Provider value={contextProps}>
			<div className={concatenatedClassName}>
				<div className="StockMessage--Container">{children}</div>
			</div>
		</StockContext.Provider>
	);
}

const NO_STOCK_NAMES = ['none', 'pre'];

const STOCK_MESSAGES = {
	good: 'In Stock',
	low: 'In Stock',
	none: 'Delayed Delivery',
	pre: 'Pre-Order',
};

const STOCK_MESSAGES_CLASS = {
	good: 'Instock',
	low: 'Instock',
	none: 'Delayed',
	pre: 'Pre',
};

interface StockMessageProps {
	deal?: Deal;
	variant?: Variant;
	children: ReactNode[] | ReactNode;
	className?: string;
	modal?: boolean;
}
