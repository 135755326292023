type FetchOptionsProps = Omit<RequestInit, 'headers' | 'body'> & {
	headers?: Record<string, string> | Headers;
	body?: Record<string, any> | string;
	throwOnError?: boolean;
};

export interface ResponseProps<T = unknown> {
	status: number;
	ok: boolean;
	data: T;
}

export function Fetch<T = unknown>(
	url: string,
	options: FetchOptionsProps = {}
): Promise<ResponseProps<T>> {
	if (options.throwOnError === undefined) {
		options.throwOnError = true;
	}

	const headers = new Headers({
		Accept: 'application/json',
		'X-CSRF-TOKEN': document
			.querySelector('meta[name="csrf-token"]')
			.getAttribute('content'),
		...options.headers,
	});

	options.headers = headers;

	if ('body' in options && !(options.body instanceof FormData)) {
		options.body = JSON.stringify(options.body);
		if (!('Content-Type' in options.headers))
			headers.append('Content-Type', 'application/json');
	}

	return fetch(url, options as RequestInit).then(function (resp) {
		if (resp.ok) {
			// Don't parse empty response
			if (resp.status === 204) {
				return {
					status: resp.status,
					ok: resp.ok,
					data: undefined,
				};
			}

			return resp.json().then(function (data) {
				return {
					status: resp.status,
					ok: resp.ok,
					data,
				};
			});
		} else {
			if (!options.throwOnError) {
				return resp.json().then(function (data) {
					return {
						status: resp.status,
						ok: resp.ok,
						data,
					};
				});
			}

			return resp.json().then(function (data) {
				throw {
					status: resp.status,
					ok: resp.ok,
					data,
				};
			});
		}
	});
}
