import React, {
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useEffect,
	useRef,
} from 'react';
import ReactDOM from 'react-dom';
import './CentredModal.scss';
import { KeyCode } from 'enums/KeyCode.enum';
import classnames from 'classnames';

const FOCUSABLE_ELEMENTS = [
	'a[href]',
	'area[href]',
	'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
	'select:not([disabled]):not([aria-hidden])',
	'textarea:not([disabled]):not([aria-hidden])',
	'button:not([disabled]):not([aria-hidden])',
	'iframe',
	'object',
	'embed',
	'[contenteditable]',
	'[tabindex]:not([tabindex^="-"])',
];

interface CentredModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	hasPadding?: boolean;
	taggingName?: string;
	dependencies?: unknown[];
	lockBody?: boolean;
}

export function CentredModal({
	children,
	isOpen,
	setIsOpen,
	hasPadding = true,
	lockBody = true,
	dependencies: dependencies = [],
}: PropsWithChildren<CentredModalProps>): JSX.Element {
	const modal = useRef<HTMLDialogElement>(null);

	function focusFirst(): void {
		const firstElement: HTMLElement = modal.current.querySelector(
			FOCUSABLE_ELEMENTS.join(',')
		);
		if (firstElement) {
			firstElement.focus();
		}
	}

	function handleKeyDown(event: KeyboardEvent): void {
		if (!isOpen) return;
		if (event.key === KeyCode.Escape) setIsOpen(false); // esc
		if (event.key === KeyCode.Tab) handleTab(event); // tab
	}

	function handleTab(event: KeyboardEvent) {
		const focusableNodes = [
			...modal.current.querySelectorAll(FOCUSABLE_ELEMENTS.join(',')),
		] as HTMLElement[];

		if (focusableNodes.length === 0) return;

		const focusedItemIndex = focusableNodes.indexOf(
			document.activeElement as HTMLElement
		);

		// If we're on the first focusable element and we shift tab goto the last focusable.
		if (event.shiftKey && focusedItemIndex === 0) {
			focusableNodes[focusableNodes.length - 1].focus();
			event.preventDefault();
		}

		// If we're on the last focusable element goto the first.
		if (
			!event.shiftKey &&
			focusableNodes.length > 0 &&
			focusedItemIndex === focusableNodes.length - 1
		) {
			focusableNodes[0].focus();
			event.preventDefault();
		}
	}

	useEffect(() => {
		if (!isOpen) return;

		focusFirst(); // Activate the first focusable event (should be a close button)
		document.addEventListener('keydown', handleKeyDown);
		if (lockBody) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			if (lockBody) {
				document.body.style.overflow = '';
			}
		};
	}, [isOpen, ...dependencies]);

	if (!isOpen) return null;

	return ReactDOM.createPortal(
		<div
			className={classnames('CentredModal', {
				CentredModal__Padding: hasPadding,
			})}
		>
			<div
				className={classnames('CentredModal--Overlay', {
					'CentredModal--Overlay__Close': !isOpen,
				})}
				onClick={() => setIsOpen(false)}
				aria-hidden={true}
			/>
			<dialog
				ref={modal}
				aria-modal={true}
				aria-labelledby="dialog_label"
				className="CentredModal--Container"
				open={isOpen}
			>
				{children}
			</dialog>
		</div>,
		document.getElementById('CentredModalRoot')
	);
}
