/**
 * DealPanel main component
 *
 * Handles custom renderer components for deal panel content and
 * footer
 */
import React, { CSSProperties, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Panel } from '@ao-internal/react-components';

import {
	DealPanelContent,
	DealPropType,
	DealPanelFooter,
	DealContext,
} from '.';
import { Deal as DealType, Colour as ColourType } from 'components/types';

import { SimFreeDealPanelFooter } from 'components/Common/Deals/DealPanel/SimFreeDealPanelFooter';
import { PromoLabel } from 'components/Common/Deals/DealPanel/PromoLabel';
import { DealMetaData } from 'components/Common/Deals/DealPanel/DealMetaData';

const simoPills = {
	'116273Aug22AOV50': {
		name: 'Includes £50 AO Voucher',
		slug: 'includes-50-ao-voucher',
		tagLine: 'Includes £50 AO Voucher',
		primaryHex: 'CC254D',
		secondaryHex: 'FFFFFF',
	},
	'1151944Jan22AOV60': {
		name: 'Includes £60 AO Voucher',
		slug: 'includes-60-ao-voucher',
		tagLine: 'Includes £60 AO Voucher',
		primaryHex: 'CC254D',
		secondaryHex: 'FFFFFF',
	},
};

const DefaultPanelLayoutRenderer = ({ Content, Footer }) => (
	<>
		{Content}
		{Footer}
	</>
);

const getShouldRenderPromo = (extraProps: Record<any, any>): boolean => {
	return !extraProps.dontRenderPromo;
};

function DealPanel(props) {
	const deal: DealType = props.deal;
	const [selectedColour, setSelectedColour] = useState<ColourType>(
		(deal.variant && deal.variant.colour) || null
	);

	const shouldRenderPromo =
		getShouldRenderPromo(props.extraProps) && !props.isSummary;

	if (!deal.promo && deal.tariff && simoPills[deal.tariff.oracleCode]) {
		deal.promo = simoPills[deal.tariff.oracleCode];
	}

	const dealPanelContentProps = {
		...props,
		deal,
	};

	let { panelWrapperClassName } = props;

	const {
		isSummary = false,
		panelLayoutRenderer = null,
		panelFooterRenderer = null,
		list,
		position,
	} = props;

	const style: CSSProperties = {};

	// // hardcode hex value to view BF deal panel css primary neon green - befcc8, primary blue - 021442
	// if (deal.promo) {
	// 	deal.promo.primaryHex = 'c82c41';
	// 	// deal.promo.secondaryHex = 'befdc8';
	// }

	if (deal.promo && shouldRenderPromo) {
		panelWrapperClassName += ` primary_${deal.promo.primaryHex.toLowerCase()} secondary_${deal.promo.secondaryHex?.toLowerCase()}`;
		panelWrapperClassName += ' deal-box-has-promo';
		style.borderColor = `#${deal.promo.primaryHex}`;
	}

	if (deal.tariff) {
		panelWrapperClassName += ' network--' + deal.tariff.network.slug;
		if (deal.tariff.type) {
			panelWrapperClassName += ' type--' + deal.tariff.type.slug;
		}
	}

	// Allow custom renderer for panel footer, falling back to default
	const FooterComponent =
		panelFooterRenderer ||
		(deal.type === 'sim-free' ? SimFreeDealPanelFooter : DealPanelFooter);

	// Allow custom renderer for panel layout, falling back to default
	const PanelLayoutRenderer =
		panelLayoutRenderer || DefaultPanelLayoutRenderer;

	const simFreeHandsetSticker =
		deal.type === 'sim-free' &&
		deal['variant']['handset']['sticker']['sim_free']
			? deal['variant']['handset']['sticker']['sim_free']
			: null;

	if (deal.promo && shouldRenderPromo) {
		panelWrapperClassName += ' deal-box-has-promo';
	}

	if (
		simFreeHandsetSticker &&
		deal.type &&
		deal.type === 'sim-free' &&
		deal.promo !== undefined
	) {
		style.borderColor = simFreeHandsetSticker.background_colour;
		panelWrapperClassName += ' deal-box-has-promo';

		// TODO: BF add promo slug matching
		if (
			deal.promo.tagLine.toLowerCase().includes('flash') &&
			deal.promo.tagLine.toLowerCase().includes('black friday')
		) {
			panelWrapperClassName += ' jan-sales-flash-promo';
		}
		// TODO: BF add promo slug matching
		// if (deal.promo.tagLine.toLowerCase().includes('black-friday')) {
		//   panelWrapperClassName += ' christmas-dark-promo';
		// }
	}
	// All deals when BF theme is active are dark promos by default
	panelWrapperClassName += ' jan-sales-dark-promo';

	return (
		<div
			className={
				!isSummary
					? `u-pt--xsmall u-pb--tiny o-flex-item u-grid--12 primary_${
							deal.promo && deal.promo.primaryHex.toLowerCase()
					  } secondary_${
							deal.promo && deal.promo.secondaryHex?.toLowerCase()
					  } ${panelWrapperClassName}`
					: null
			}
			itemProp="offers"
			itemType="http://schema.org/Offer"
			itemScope
		>
			<DealMetaData deal={deal} />

			<DealContext.Provider
				value={{
					deal,
					position,
					list,
					selectedColour,
					setSelectedColour,
				}}
			>
				{shouldRenderPromo &&
					(deal.promo ||
						(deal.type &&
							deal.type === 'sim-free' &&
							simFreeHandsetSticker &&
							deal.promo !== undefined)) && <PromoLabel />}
				<Panel
					className={classnames(
						'u-p--tiny deal-panel u-d--flex u-fxd--column',
						props.panelClassName
					)}
					style={style}
				>
					{!isSummary
						? PanelLayoutRenderer({
								Content: (
									<DealPanelContent
										{...dealPanelContentProps}
									/>
								),
								Footer: (
									<FooterComponent {...props} deal={deal} />
								),
						  })
						: PanelLayoutRenderer({
								Content: (
									<DealPanelContent
										{...dealPanelContentProps}
									/>
								),
						  })}
				</Panel>
			</DealContext.Provider>
		</div>
	);
}

DealPanel.propTypes = {
	/**
	 * @param deal cloud9 handset deal
	 *
	 * If deal is an array it will be treated as a list of variants
	 */
	deal: DealPropType,
	openModalWithDeal: PropTypes.func.isRequired,

	/**
	 * @param panelClassName optional className for the deal panel
	 */
	panelClassName: PropTypes.string,
	/**
	 * @param panelWrapperClassName optional className for the panel wrapper
	 */
	panelWrapperClassName: PropTypes.string,

	panelContentRenderer: PropTypes.func.isRequired,
	panelFooterRenderer: PropTypes.func,
	isSummary: PropTypes.bool,

	/**
	 * @param panelLayoutRenderer optional panel layout renderer
	 * typically used for custom layout of content and footer
	 * e.g. content in 8/12 column next to footer in a 4/12 column
	 */
	panelLayoutRenderer: PropTypes.func,
};

export { DealPanel };
