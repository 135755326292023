/**
 * DealList
 *
 * Lists deals and provides a single modal for all deals
 * to populate
 *
 * This is used for single deals that require a modal
 * so should maybe be named something other than DealList
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DealPanel, DealPropType } from './DealPanel';
import { DealModal } from './DealModal';
import { PromoInjector } from './PromoInjector';
import { ScreenSizeProvider } from '../use-screen-size';
import { Deal } from 'components/types';
import { ErrorBoundary } from 'components/Common/ErrorBoundary';

export interface DealListProps {
	deals: Deal[];
	paginated?: boolean;
	panelClassName?: string;
	panelWrapperClassName?: string;

	panelContentRenderer: (any: any) => any;
	panelFooterRenderer?: (any: any) => any;
	panelLayoutRenderer?: (any: any) => any;
	panelFooterButton?: (any: any) => any;

	isSummary?: boolean;
	list?: any;
	extraProps?: Record<string, any>;

	isTopDeal?: boolean;
}

function DealList({
	panelClassName = '',
	panelWrapperClassName = '',
	panelLayoutRenderer = null,
	panelContentRenderer,
	panelFooterRenderer = null,
	panelFooterButton = null,
	isSummary = false,
	deals,
	list = null,
	extraProps = {},
	isTopDeal = false,
}: DealListProps) {
	const [modalOpen, setModalOpen] = useState(false);
	const [modalDeal, setModalDeal] = useState(null);
	const [modalRenderer, setModalRenderer] = useState(null);
	const [modalTitle, setModalTitle] = useState('');

	function openModalWithDeal({ deal, Renderer, title }) {
		setModalRenderer(() => Renderer);
		setModalTitle(title);
		setModalDeal(deal);
		setModalOpen(true);
	}

	return (
		<>
			<ScreenSizeProvider>
				<PromoInjector>
					{deals.map((deal, index) => (
						<ErrorBoundary key={index}>
							<DealPanel
								panelClassName={panelClassName}
								panelWrapperClassName={panelWrapperClassName}
								deal={deal}
								// @ts-expect-error TODO
								position={index + 1}
								openModalWithDeal={openModalWithDeal}
								panelContentRenderer={panelContentRenderer}
								panelFooterRenderer={panelFooterRenderer}
								panelLayoutRenderer={panelLayoutRenderer}
								panelFooterButton={panelFooterButton}
								isSummary={isSummary}
								list={list}
								extraProps={extraProps}
								isTopDeal={isTopDeal}
							/>
						</ErrorBoundary>
					))}
				</PromoInjector>
			</ScreenSizeProvider>
			<DealModal
				deal={modalDeal}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				Renderer={modalRenderer}
				title={modalTitle}
			/>
		</>
	);
}

DealList.propTypes = {
	/**
	 * @param deals cloud9 deals response object
	 */
	deals: PropTypes.arrayOf(DealPropType).isRequired,

	/**
	 * @param paginated optional, defaults to false
	 *
	 * Whether the response is paginated. If true,
	 * expects a paginatedDealsResponse and shows Load More button
	 */
	paginated: PropTypes.bool,

	/**
	 * @param panelClassName optional className for the deal panel
	 */
	panelClassName: PropTypes.string,
	/**
	 * @param panelWrapperClassName optional className for the panel wrapper
	 * Typical use case, adding u-grid--4--bp-medium for responsive grid
	 */
	panelWrapperClassName: PropTypes.string,

	/**
	 * @param panelContentRenderer a renderer for deal panel content that recieves
	 * all deal components to custom arrangement of deal panel
	 *
	 * See DealPanelContent for all components and props that are passed
	 * to panelContentRenderer function
	 *
	 * Example usage: <DealList panelContentRenderer={({ Dealtitle })=>{
	 *    return <div><DealTitle/></div>
	 * }}/>
	 */
	panelContentRenderer: PropTypes.func.isRequired,

	/**
	 * @param panelFooterRenderer optional footer renderer
	 */
	panelFooterRenderer: PropTypes.func,

	/**
	 * @param panelLayoutRenderer optional panel layout renderer
	 * typically used for custom layout of content and footer
	 * e.g. content in 8/12 column next to footer in a 4/12 column
	 */
	panelLayoutRenderer: PropTypes.func,
};

export { DealList };
